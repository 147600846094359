<template>
	<div class="QY_msg">
		<!-- <div class="form_left">
			<el-button type="primary" :class="showIndex==1?'active':'btn'" @click="tab(1)">基本信息</el-button>
			<el-button type="primary"  :class="showIndex==2?'active':'btn'" @click="tab(2)">求职信息</el-button>
		</div> -->
		
		<!-- 基本信息 -->
		<div class="form_center">
			
			
			<!-- <img src="../../assets/img/form.png" class="login_logo" alt=""> -->
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm img_form">
				<div class="center_top">
					<div class="top_dian">
						
					</div>
					<div class="top_text">
						<!-- 基本信息 -->{{$t('QY_msg.JBXX')}}
					</div>
				</div>
				<el-form-item :label="$t('EMAILPHONE')" prop="account">					
					<div style="display: flex;align-items: center;">
						<!-- <el-input v-model="ruleForm.account"></el-input> -->
						<div>{{ruleForm.account}}</div>
						<el-tag effect="plain" size="mini" style="margin-left: 10px;"><!-- 非公开 -->{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item>
				<el-form-item :label="$t('QY_msg.QYMC')" prop="business_name">
					<el-input v-model="ruleForm.business_name"></el-input>
				</el-form-item>
				<!-- 所属行业 -->
				<el-form-item :label="$t('QY_msg.SSHY')" prop="industry_id">
					<!-- <el-select v-model="ruleForm.industry" :placeholder="$t('QXZ')">
					    <el-option v-for="item in industry"
					      :key="item.data_code"
					      :label="item.data_value"
					      :value="item.data_value">
					    </el-option>
					  </el-select> -->
					  <el-cascader style="width: 100%;" :options="industry" v-model="ruleForm.industry_id" :props="{label:'label',value:'value'}" :placeholder="$t('QXZ')"></el-cascader>
				</el-form-item>
				<el-form-item :label="$t('QY_msg.SSXX')" prop="listed_mehod">
					<el-radio-group v-model="ruleForm.listed_mehod">
						<el-radio :label="item.data_code/1" v-for="item in listedMehod" :key="item.data_code">{{item.data_value}}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item :label="$t('QY_msg.XSGM')" prop="sales_volume">
					<el-select v-model="ruleForm.sales_volume" :placeholder="$t('QXZ')">
					    <el-option v-for="item in salesVolume"
					      :key="item.data_code"
					      :label="item.data_value"
					      :value="item.data_value">
					    </el-option>
					  </el-select>
				</el-form-item>
				<el-form-item :label="$t('QY_msg.YGGM')" prop="employee_num">
					<el-select v-model="ruleForm.employee_num" :placeholder="$t('QXZ')">
					    <el-option v-for="item in employeeNum"
					      :key="item.data_code"
					      :label="item.data_value"
					      :value="item.data_value">
					    </el-option>
					  </el-select>
				</el-form-item>
				<el-form-item :label="$t('QY_msg.ZFFS')" prop="payment_method">
					<el-select v-model="ruleForm.payment_method" :placeholder="$t('QXZ')">
					    <el-option v-for="item in paymentMethod"
					      :key="item.data_code"
					      :label="item.data_value"
					      :value="item.data_value">
					    </el-option>
					  </el-select>
				</el-form-item>
				<!-- <el-form-item :label="$t('QY_msg.PJXS')" prop="evaluate_list">
					<el-checkbox-group v-model="ruleForm.evaluate_list">
						<el-checkbox :label="item.data_value" v-for="item in evaluateList" :key="item.data_code">{{item.data_value}}</el-checkbox>
					</el-checkbox-group>
				</el-form-item> -->
				
				
				
				
				<el-form-item :label="$t('QY_msg.QYGW')">
					<el-input v-model="ruleForm.bus_website"></el-input>
				</el-form-item>
				<el-form-item :label="$t('my_msg.LXFS')" >
					<el-input v-model="ruleForm.contact_info" type="number"></el-input>
				</el-form-item>
				<el-form-item :label="$t('QY_msg.YZBM')" prop="postcode">
					<div style="display: flex;align-items: center;">
						<el-input v-model="ruleForm.postcode" type="number"></el-input>
						<el-tag effect="plain" size="mini" style="margin-left: 10px;"><!-- 非公开 -->{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item>
				<el-form-item :label="$t('QY_msg.DDFX')" prop="cityname">
					<div style="display: flex;align-items: center;">
						<!-- <el-input v-model="ruleForm.name"></el-input> -->
						<!-- <template v-if="lang=='zh'">
						<el-cascader @change="selectCity" :options="citydata" v-model="ruleForm.cityname" :props="{label:'name',value:'name'}" :placeholder="$t('QXZ')"></el-cascader>
						</template>
						<template v-else> -->
						<el-cascader @change="selectCity" :options="citydata" v-model="ruleForm.cityname" :props="{label:'label',value:'label'}" :placeholder="$t('QXZ')"></el-cascader>
						<!-- </template> -->
						<el-tag effect="plain" size="mini" style="margin-left: 10px;"><!-- 非公开 -->{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item>
				<!-- <el-form-item :label="$t('QY_msg.DWDZ')" prop="location">
					<div style="display: flex;align-items: center;">
						<el-input v-model="ruleForm.location"></el-input>
						<el-tag effect="plain" size="mini" style="margin-left: 10px;">{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item> -->
				<!-- <el-form-item :label="$t('QY_msg.XQDZ')" prop="address">
					<div style="display: flex;align-items: center;">
						<el-input v-model="ruleForm.address" :placeholder="$t('ADRTIP')"></el-input>
						<el-tag effect="plain" size="mini" style="margin-left: 10px;">{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item> -->
				<el-form-item :label="$t('QY_msg.QYLOGO')" >
					<div class="imglist" v-if="ruleForm.avatar_url">
						<div class="item" >
							<img  :src="ruleForm.avatar_url" >
							<div class="delbtn" @click="ruleForm.avatar_url=''"><i class="el-icon-close"></i></div>
						</div>
					</div>
					
					<el-upload
						style="display: inline;"
					  class="avatar-uploader"
					  :action="$config.uploadUrl"
					  :show-file-list="false"
					  :on-success="upload_avatar"
					  list-type="picture-card"
					  >
					   <i class="el-icon-plus"></i>
					 
					</el-upload>
					
				</el-form-item>
				
				<el-form-item :label="$t('QY_msg.QYJJ')" >
					<el-input
					  type="textarea"
					  :rows="4" maxlength="1000" show-word-limit
					  :placeholder="$t('QSR')"
					  v-model="ruleForm.description">
					</el-input>
				</el-form-item>
				
				<el-form-item :label="$t('OTHERLINK')" >
					<el-input v-model="ruleForm.linkedin_url"></el-input>
				</el-form-item>
			
		
			<div class="center_top" style="margin-bottom: 0;">
				<div class="top_dian">
					
				</div>
				<div class="top_text">
					<!-- 资质信息 -->{{$t('QY_msg.ZZXX')}}
				</div>
			</div>
			<el-form-item label=" " style="margin-bottom: 0;">
				{{$t('QY_msg.YYZCMSG')}}
			</el-form-item>
				
				
				<el-form-item :label="$t('QY_msg.YYZC')" >
					<imglist :datas="ruleForm.certificate_list"></imglist>
					<el-upload style="display: inline;"
					  :action="$config.uploadUrl"
					  list-type="picture-card"
					   :show-file-list="false"
					   :on-success="upload_cert"
					    multiple>
					  <i class="el-icon-plus"></i>
					</el-upload>
				</el-form-item>
				<el-form-item :label="$t('QY_msg.QTZJ')">
					<imglist :datas="ruleForm.othercert_list"></imglist>
					<el-upload style="display: inline;"
					  :action="$config.uploadUrl"
					  list-type="picture-card"
					   :show-file-list="false"
					   :on-success="upload_othercert"
					   multiple>
					  <i class="el-icon-plus"></i>
					</el-upload>
				</el-form-item>
			</el-form>
			<el-button type="primary" @click="submitForm('ruleForm')"><!-- 确认 -->{{$t('CONFIRM')}}</el-button>
		</div>
		
	</div>
</template>

<script>
	import imglist from '@/components/imglist.vue';
	export default {
		components: {
			imglist
		},
		data() {
			return {
				
				
				
				citydata:[],//省市区数据				
				industry:[],
				listedMehod:[],
				salesVolume:[],
				employeeNum:[],
				paymentMethod:[],
				evaluateList:[],
				ruleForm: {					
					user_id: '',
					business_name: '',
					industry_id: '',
					listed_mehod: 0,
					sales_volume: '',
					employee_num: '',
					payment_method: '',
					evaluate_list: [],
					linkedin_url: '',
					bus_website: '',
					avatar_url: '',
					description: '',
					certificate_list: [],
					othercert_list:[],
					contact_info: '',
					postcode: '',
					//country: '',
					province: '',
					city: '',
					area: '',
					location: '',
					address: '',
					cityname:[],
					
				},
				rules: {
					
					business_name: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					industry_id: [{required: true,trigger: 'change',message:this.$t('QXZ')}],
					sales_volume: [{required: true,trigger: 'change',message:this.$t('QXZ')}],
					employee_num: [{required: true,trigger: 'change',message:this.$t('QXZ')}],
					payment_method: [{required: true,trigger: 'change',message:this.$t('QXZ')}],
					linkedin_url: [{required: true,trigger: 'blur',message:this.$t('QSR')}],					
					bus_website: [{required: true,trigger: 'blur',message:this.$t('QXZ')}],
					avatar_url: [{required: true,trigger: 'change',message:this.$t('QSC')}],					
					description: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					certificate_list: [{type:'array',required: true,trigger: 'change',message:this.$t('QSC')}],					
					othercert_list: [{type:'array',required: true,trigger: 'change',message:this.$t('QSC')}],					
					contact_info: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					postcode: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					location: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					address: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					captcha_code: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					cityname: [{required: true,trigger: 'change',message:this.$t('QXZ')}],
				},
				
			};
		},
		async created() {
			this.showIndex=this.$route.query.showIndex||1;
			this.getCitys();
			// if(this.lang=='jp'){
			// 	this.getCitys();
			// }else{
			// 	this.citydata=require('@/assets/js/city.json'); // 直接引入json文件;
			// }
			this.getTndustry();
			this.getUnit();
			await this.getUserInfo();
			
			
			
		},
		
		methods:{
			
			//获取岗位数据
			async getCate(){
				let res=await this.$request.get('/api/setjobs/getAllCateList');
				//this.cateList=res.data.records;
			},
			//上传头像
			async upload_avatar(res,file){
				console.log(res);
				this.ruleForm.avatar_url = res.data.Location;
			},
			//上传其他证书
			upload_othercert(response, file, fileList){				
				
				let files=JSON.parse(JSON.stringify(this.ruleForm.othercert_list));
				files.push(response.data.Location);				
				this.ruleForm.othercert_list=files;
				//console.log(this.ruleForm.portfolio);
			},
			//上传营业执照
			upload_cert(response, file, fileList){				
				
				let files=JSON.parse(JSON.stringify(this.ruleForm.certificate_list));
				files.push(response.data.Location);				
				this.ruleForm.certificate_list=files;
				//console.log(this.ruleForm.portfolio);
			},
			
			//获取基础信息
			async getUserInfo(){
				let res=await this.$request.get('/api/bususer/getBusInfo');
				for (let i in res.data){
					if(res.data[i]){
						this.ruleForm[i]=res.data[i];
					}
				}
				
				this.ruleForm.cityname=[res.data.province,res.data.city];
				
				
				
			},
			
			
			//获取字典数据
			async getUnit(){
				let res=await this.$request.get('/api/sysdict/getDictList');
				this.listedMehod=res.data.listed_mehod.data;
				this.salesVolume=res.data.sales_volume.data;
				this.employeeNum=res.data.employee_num.data;
				this.paymentMethod=res.data.payment_method.data;
				this.evaluateList=res.data.evaluate_list.data;
				//this.industry=res.data.industry.data;
				
				
			},
			//获取行业数据
			async getTndustry(){
				let res=await this.$request.get('/api/sysdict/getAllIndustryList');
				this.industry=res.data.records;
			},
			//获取省市区数据
			async getCitys(){
				let res=await this.$request.get('/api/sysdict/getJapanCityCode');
				this.citydata=res.data.records;
			},
			selectIndustry(e){
				console.log(e);
			},
			//选择省市区
			selectCity(val){
				console.log(val);
				this.ruleForm.province=val[0];
				this.ruleForm.city=val[1];
				if(val.length>2){
					this.ruleForm.area=val[2];
				}else{
					this.ruleForm.area='';
				}
				console.log(this.ruleForm);
				//this.$refs.ruleForm.$emit('el.form.change');
			},
			//保存基础信息
			async submitForm(){				
				let data=JSON.parse(JSON.stringify(this.ruleForm));
				// data.industry_id=data.industry_id[data.industry_id.length-1];
				delete data.cityname;
				delete data.industry;
				console.log(data);
				this.$refs['ruleForm'].validate(async (valid) => {
				  if (valid) {
					let res=await this.$request.post('/api/bususer/updateBusInfo',data);
					this.$message({
						message:res.message,
						type:'success'
					});
					this.$request.reg({userid:this.ruleForm.user_id,nickname:this.ruleForm.business_name,faceurl:this.ruleForm.avatar_url});
					this.$router.go(-1);
				  } else {
					this.$message({
						message:this.$t('QTXWZXX'),
						type:'warning'
					});
				  }
				});
			},
			
		}
	};
</script>

<style lang="less" scoped>
	.imglist{display: inline-flex;flex-wrap: wrap;
		.item{width: 148px;height: 148px;position: relative;border: 1px solid #c0ccda;border-radius: 6px;margin:0 8px 8px 0;box-sizing: border-box;
			img{width: 100%;height: 100%;border-radius: 6px;object-fit: cover;}
			.delbtn{position: absolute;right: -1px;top: -1px;width:20px;height: 20px;background-color:#f00;border-radius: 0 6px 0 6px;font-size: 12px;color: #fff;display: flex;align-items: center;justify-content: center;line-height: 1.2;cursor: pointer;visibility:hidden;}
			&:hover{
				.delbtn{visibility: visible;}
			}
		}
	}
	.QY_msg {
		// padding: 30px;
		min-height: 100%;
		background-color: #212121;
		font-size: 14px;
		display: flex;
		justify-content: center;
		// flex-direction: column;
		// align-items: center;
		padding-top: 90px;
		padding-bottom: 90px;
		.form_left{
			display: flex;
			flex-direction: column;
			margin-right: 25px;
			.btn{
				width: 286px;
				height: 51px;
				background: #212121;
				border-radius: 7px;
				border: 1px solid #567BB6;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
				box-sizing: border-box;
				margin-bottom: 20px;
			}
			.btn:hover{
				width: 286px;
				height: 51px;
				background: #567BB6;
				border-radius: 7px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: none;
				color: #fff;
				margin-bottom: 20px;
			}
			.active{
				width: 286px;
				height: 51px;
				background: #567BB6;
				border-radius: 7px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: none;
				color: #fff;
				margin-bottom: 20px;
			}
			.el-button{
				margin-left: 0;
			}
		}
		.form_center {
			width: 874px;
			padding: 30px;
			background-color: #fff;
			border-radius: 20px;
			display: flex;
			flex-direction: column;
			align-items: center;
			.center_top{
				height: 40px;
				display: flex;
				align-items: center;
				
				border-bottom: 1px solid #567BB6;
				margin-bottom: 20px;
				position: relative;
				.top_dian{
					width: 5px;
					height: 15px;
					background: #567BB6;
					margin-right: 10px;
				}
				.top_name{
					font-size: 17px;
					font-weight: bold;
				}
			}
			.img_form{
				width: 700px;
				.avatar-uploader .el-upload {
				    border: 1px dashed #d9d9d9;
				    border-radius: 6px;
				    cursor: pointer;
				    position: relative;
				    overflow: hidden;
				  }
				  .avatar-uploader .el-upload:hover {
				    border-color: #409EFF;
				  }
				  .avatar-uploader-icon {
				    font-size: 20px;
				    color: #8c939d;
				    width: 86px;
				    height: 86px;
				    line-height: 86px;
				    text-align: center;
				  }
				  .avatar {
				    width: 86px;
				    height: 86px;
				    display: block;
				  }
			}
		}
		.top_btn{
			width: 120px;
			height: 29px;
			// background: #567BB6;
			border-radius: 7px;
			border: none;
			display: flex;
			align-items: center;
			justify-content: center;
			// margin-left: 30px;
			position: absolute;
			// top: 10px;
			right: 0;
		}
		.el-form{
			margin-bottom: 20px;
		}
		.picker_input{
			width: 260px;
		}
		.textarea{
			width: 630px;
		}
		.form_left{
			width: 286px;
		}
		.login_logo {
			width: 389px;
			height: 72px;
			margin-bottom: 30px;
		}

	}
	.formpart{position: relative;
		&:before{content:"";display: block;width: 771px;border-top: 1px solid #ddd;margin:0 auto 20px;}
		&:first-child:before{content:none;}
		.delbtn{position: absolute;top: 0;right: 50px;}
	}
</style>
